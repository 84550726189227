<template>
  <ion-menu auto-hide="true" menuId="menustudio" side="start" menu-id="menustudio" class="menustudio"
    content-id="main-content" type="overlay">
    <ion-content class="sideMenu">
      <div class="back_menu_o">
        <ion-list id="inbox-list">
          <ion-list-header style="display:none"><strong>Nombre cliente</strong></ion-list-header>
          <section class="logoutSection">
            <div>
              <div class="tb_cerrarS">
                <img :src="clientImageSrc ? clientImageSrc : 'assets/ICONOS/close_f.svg'"
                  :style="'background-color:' + (client_color ? client_color : 'white')" />
                <span class="link" :class="link && link.length > 8 ? 'link_chequeto' : ''"><small>/</small>{{ link ?
                  link
                  :
                  'Cargando...' }}</span>
                <span class="icon_closee" @click="logout" style="cursor:pointer;"><ion-icon
                    src="assets/ICONOS/cabina/log-out-outline.svg"
                    title="Salir del canal"></ion-icon><span></span></span>
              </div>
            </div>
            <div class="admin_area" :class="cabina_user == null || cabina_user == '' ? 'log_user_off' : 'log_user_on'"
              v-show="device == web || device == 'web'">
              <p class="admin_title"><small>Administrador</small></p>
              <button class="tb_cabina_user_logged" disabled>
                <span class="title_icon" @click="menuNavigation('/admin/dashboard');">
                  <img :src="'assets/ICONOS/user.svg'" />
                  <span class="title_name">{{ cabina_user }}</span>
                </span>
                <span class="title_logout" @click="signOutCabina"><ion-icon
                    src="assets/ICONOS/cabina/log-out-outline.svg"
                    title="Cerrar sesión de administrador"></ion-icon></span>
                <small
                  style="font-size: 10px; color: #dfdfde; margin: 5px; display: block; text-align: center; width: 100%;  padding:5px; cursor: pointer;"
                  @click="menuNavigation('/admin/dashboard');">La sesión expira a las: {{
                    token_expiration_legible_date ? token_expiration_legible_date.split(' ')[1] : ''
                  }}</small>
              </button>
              <button class="tb_cabina_login" @click="menuNavigation('/admin/loginAdmin');">
                <span>Iniciar Sesion</span>
              </button>
            </div>
            <div></div>
            <!--<ion-button class="logoutButton" fill="outline" 
              style="margin-top: 20px; text-transform: none;font-weight: 600; color: #dfdfde;" 
              v-on:click="logout()" >Cerrar sesión</ion-button>

              <ion-button class="adminButton" fill="outline" 
              style="margin-top: 20px; text-transform: none;font-weight: 600; color: #dfdfde;" 
              v-on:click="menuNavigation('/admin/loginAdmin');" >Administrador</ion-button>
              -->


          </section>
        </ion-list>
        <ion-list class="tabs_lat_menu">
          <ion-item class="l_tabReproductor dex_item" id="l_tabReproductor" button
            :class="repSolo ? 'repSolo' : 'hiddenMenuItem'" @click="menuNavigation('/tabs/tabReproductor')">
            <ion-icon v-show="$route.name !== 'tabReproductor'" class="i_gris"
              src="assets/ICONOS/reproductor-gris.svg" />
            <ion-icon v-show="$route.name == 'tabReproductor'" class="i_blanco"
              src="assets/ICONOS/reproductor-blanco.svg" />
            <ion-label :class="$route.name == 'tabReproductor' ? 'selectedTab' : ''">Reproductor</ion-label>
          </ion-item>
          <ion-item class="l_tabCategorias dex_item hiddenMenuItem" id="l_tabCategorias" button
            @click="menuNavigation('/tabs/tabCategorias'); getddata2();">
            <ion-icon v-show="$route.name !== 'tabCategorias'" class="i_gris"
              src="assets/ICONOS/categoria-musical-gris.svg" />
            <ion-icon v-show="$route.name == 'tabCategorias'" class="i_blanco"
              src="assets/ICONOS/categoria-musical-blanco.svg" />
            <ion-label :class="$route.name == 'tabCategorias' ? 'selectedTab' : ''">Categorias musicales</ion-label>
          </ion-item>
          <ion-item class="l_tabVariantes dex_item hiddenMenuItem" id="l_tabVariantes" button
            @click="menuNavigation('/tabs/tabVariantes'); getddata2();">
            <ion-icon v-show="$route.name !== 'tabVariantes'" class="i_gris" src="assets/ICONOS/variante-gris.svg" />
            <ion-icon v-show="$route.name == 'tabVariantes'" class="i_blanco" src="assets/ICONOS/variante-blanco.svg" />
            <ion-label :class="$route.name == 'tabVariantes' ? 'selectedTab' : ''">Contenido por sucursal</ion-label>
          </ion-item>
          <ion-item class="l_tabAudios dex_item hiddenMenuItem dropdown_p" id="l_tabAudios" button
            :class="{ dropdown_pOpen: showAudiosMenu }">
            <ion-grid>
              <ion-row v-on:click="RecargarClient()" @click="showAudiosMenu = !showAudiosMenu">
                <ion-icon v-show="$route.name !== 'tabAudios'" class="i_gris" src="assets/ICONOS/audios-gris.svg" />
                <ion-icon v-show="$route.name == 'tabAudios'" class="i_blanco" src="assets/ICONOS/audios-blanco.svg" />
                <ion-label class="tabAudios_a" :class="$route.name == 'tabAudios' ? 'selectedTab' : ''">Audios a
                  demanda</ion-label>
                <ion-icon class="dropdown_arrow" src="assets/ICONOS/atras.svg" />
              </ion-row>
              <ion-row class="audios_row">
                <ion-list lines="inset" class="link_items">
                  <ion-item class="link_item" v-if="audiosReq && audiosReq.length <= 0">
                    <p class="">Usted no tiene audios a demanda.</p>
                  </ion-item>
                  <ion-item class="link_item" v-for="audio in audiosReq" :key="audio.id">
                    <ion-grid class="left_div_link">
                      <ion-row class="play_button_link">
                        <ion-col class="div">
                          <ion-icon class="play" v-on:click="confirmarAudioDemanda(audio)"
                            src="assets/ICONOS/reproductor-blanco.svg"></ion-icon>
                          <ion-icon class="pause" src="assets/ICONOS/i-pausa.svg"></ion-icon>
                        </ion-col>
                      </ion-row>
                      <ion-row class="text_p">
                        <ion-col class="div">
                          <h3 class="text">{{ audio.name }}</h3>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                </ion-list>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item class="l_tabSucursales dex_item hiddenMenuItem" id="l_tabSucursales" button
            @click="menuNavigation('/tabs/tabSucursales'); getddata2();">
            <ion-icon v-show="$route.name !== 'tabSucursales'" class="i_gris" src="assets/ICONOS/sucursales-gris.svg" />
            <ion-icon v-show="$route.name == 'tabSucursales'" class="i_blanco"
              src="assets/ICONOS/sucursales-blanco.svg" />
            <ion-label :class="$route.name == 'tabSucursales' ? 'selectedTab' : ''">ID de sucursal</ion-label>
          </ion-item>
          <ion-item class="l_tabLinks dex_item hiddenMenuItem" id="l_tabLinks" button
            @click="menuNavigation('/tabs/tabLinks'); getddata2();">
            <ion-icon v-show="$route.name !== 'tabLinks'" class="i_gris" src="assets/ICONOS/links-gris.svg" />
            <ion-icon v-show="$route.name == 'tabLinks'" class="i_blanco" src="assets/ICONOS/links-blanco.svg" />
            <ion-label :class="$route.name == 'tabLinks' ? 'selectedTab' : ''">Links</ion-label>
          </ion-item>


        </ion-list>
        <div class="vybroostudioinmenu" v-if="!sonara" @click="openMenu2();" style="cursor: pointer;">
          <!-- <ion-img src="/assets/ICONOS/servicios/vybroo-studio.svg" style="transform: scale(0.7);height: 100%;"></ion-img> -->
        </div>
      </div>

      <!-- HORA Y CORREO CONTACTO -->
      <div class="footer_menu">        
        <hr class="white_hr hr_no_margin">
        <ion-item class="storeItem">
          <div class="storeAnimation" v-show="storeAnimation"></div>
          <ion-grid class="memorySizeGrid">
            <ion-row class="memorySizeRow">
              <ion-col class="memorySizeInfodiv"> 
                <ion-text class="memorySizeInfo" color="dark"> <strong>Almacenamiento:</strong> <br>
                {{ memorySize }}</ion-text>
              </ion-col>
              <ion-col class="memorySizeIcondiv"> 
                  <span class="memorySizeIcon" @click="memorySizeFree" style="cursor:pointer;">
                    <ion-icon src="assets/ICONOS/clean-db.svg" title="Liberar almacenamiento"></ion-icon>
                  </span> 
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item> 
        <hr class="gray-hr hr_no_margin">
        <ion-item>
          <a v-if="sonara" class="mailtoTextButtonLogout" href="mailto:hola@sonara.red">
            <ion-text color="dark"> <strong>¿Necesitas ayuda?</strong> <br>
              hola@sonara.red</ion-text>
          </a>
          <a v-else class="mailtoTextButtonLogout" href="mailto:contacto@vybroo.com">
            <ion-text color="dark"> <strong>¿Necesitas ayuda?</strong> <br>
              contacto@vybroo.com</ion-text>
          </a>
        </ion-item>
        <p class="hora-actual">{{ this.horaActual }}</p>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
/* eslint-disable */
import {
  IonMenu, IonContent, IonItem, IonList, IonLabel, alertController, menuController, IonIcon, IonImg, IonText, IonButton, IonListHeader
} from "@ionic/vue";
import { Storage } from '@capacitor/storage';
import moment from 'moment-timezone';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';

export default {
  components: {
    IonMenu, IonContent, IonItem, IonList, IonLabel, IonIcon, IonImg, IonText, IonButton, IonListHeader
  },
  data() {
    return {
      timezone: null,
      clientData: null,
      clientImageSrc: null,
      link: null,
      cabina_user: null,
      client_color: null,
      token_expiration_date: null,
      token_expiration_legible_date: null,
      horaActual: "",
      horaServer: "",
      horaLocal: "",
      horaMenor: "",
      sonara: false,
      repSolo: false,
      showAudiosMenu: false,
      audios: [{
        id: 1,
        name: "Lista de Éxitos",
        imagePath: "lista-de-exitos.png"
      }],
      audiosReq: [],
      ddata: [],
      clientData: {},
      audioDemanda: {},
      permissions: {},
      device: {},
      memorySize: "Cargando...",
      storeAnimation: false,
    };
  },
  methods: {
    memorySizeFree() {
      this.storeAnimation = true;
      setTimeout(() => {
        this.storeAnimation = false;
      }, 3000);
      this.emitter.emit("memorySizeFree", "Liberando espacio de almacenamiento");
    },
    openMenu() {
      menuController.open("menustudio");
    },
    openMenu2() {
      menuController.close("menustudio");
      setTimeout(() => {
        menuController.open("menudex");
      }, 500);
    },
    async menuNavigation(url) {
      menuController.close("menustudio");

      /*if ( url == '/admin/cabinaAdmin') {
        var alert = await alertController
          .create({
            header: "Aviso",
            message: "El reproductor se detendrá para acceder a la cabina de administración, ¿Desea continuar?",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  this.emitter.emit("rep-func-pause", "Muteando Reproductor");
                  this.$router.push(url);
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  console.log('Cancelar pulsado');
                }
              },
            ]
          });
        return alert.present();

      } else {*/
      this.$router.push(url);
      //}
    },
    logout() {
      Storage.clear();
      this.emitter.emit("rep-func-pause", "Muteando Reproductor");
      this.emitter.emit("rep-func-kill", "Matando Reproductores");
      this.$router.replace("/login/loginPage");
      this.token_expiration_date = null;
      setTimeout(() => { this.$router.go() }, 100);
      // console.log("clientData removed");
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      var alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: 'Si',
              handler: () => {
                this.$router.replace("/admin/loginAdmin");
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();
    },
    async signOutCabina() {
      var alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión de administrador?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();
    },
    async getCabinaUser() {
      var storage_cabina_user = await Storage.get({ key: "cabina_admin_user" });
      if (storage_cabina_user.value) {
        this.cabina_user = storage_cabina_user.value;
        console.log("cabina_admin_user: " + this.cabina_user);

      } else {
        this.cabina_user = null;
      }
    },
    async getStorageAuthToken() {
      var storage_auth_token = await Storage.get({ key: "authToken" });
      // si el token existe y tiene fecha de expiración, validar si ya expiró, si ya expiró, eliminar el token del storage e igualar cabina_user a null
      if (storage_auth_token.value) {
        var token = storage_auth_token.value;
        var { value, expires } = JSON.parse(storage_auth_token.value);
        this.token_expiration_date = expires;

        //imprimir fecha de expiración del token en formato legible
        var date = new Date(expires).toLocaleString();
        this.token_expiration_legible_date = date.toString("dd/MM/yyyy HH:mm:ss tt");
        console.info("%c Hay un token de sesión de administrador, el cual expirará a las: " + date, 'background: #000; color: yellow; font-size: 14px;');

      } else {
        this.cabina_user = null;
        // console.log("no hay token en storage");
      }
    },
    async load_image_client() {
      var ret = await Storage.get({ key: "clientData" });
      if (ret.value) {
        try {
          this.clientData = JSON.parse(ret.value);
          // console.log("Consultando client data de storage...")
          // console.log(this.clientData);
          this.clientImageSrc = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + this.clientData.mobileimage;
          this.client_color = "#" + this.clientData.color;
          this.link = '' + this.clientData.link;
          this.cabina_user = '' + this.clientData.cabina_admin_user;
        } catch (error) {
          console.log("==Error al parsear clientData");
          console.log(error);
        }
      }
      this.getCabinaUser();
      this.getStorageAuthToken();
    },
    async getServerTime() {
      var zonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log("Zona horaria: " + zonaHoraria);
      var zonaHorariaG = zonaHoraria.replace(/\//g, "-");
      // console.log("Zona horaria en getTimeFile: " + zonaHorariaG);
      var horaServer = await axios.get('/api/hora/' + zonaHorariaG);
      // console.log("Hora del servidor desde tabsStudio: " + horaServer.data.hora);
      // horaServer.data.hora?horaServer.data.hora = horaServer.data.hora:horaServer.data.hora = "Hora no disponible";
      // console.log("Hora del servidor desde tabsStudio: " + horaServer.data.hora);
      //si el dispositivo es android o iOS, obtener la hora local
      if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android' || horaServer.data.hora == undefined) {
        var horaLocal = moment().tz(zonaHoraria).format('HH:mm:ss');
        this.horaLocal = horaLocal;
        console.log("Hora local: " + this.horaLocal);
        this.horaActual = this.horaLocal;
      } else {
        this.horaActual = horaServer.data.hora;
      }


      // var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log("Timezone: " + timeZone);
      // var horaActualEnZonaHoraria = moment().tz(timeZone).format('HH:mm:ss');
      // this.horaLocal = horaActualEnZonaHoraria;
      // console.log("Hora del servidor: " + this.horaServer);
      //     console.log("Hora del cliente: " + this.horaLocal);
      //     // guardar la hora menor en una variable
      //     if (this.horaServer < this.horaLocal){
      //       this.horaMenor = this.horaServer;
      //     } else {
      //       this.horaMenor = this.horaLocal;
      //     }
      //     console.log("Hora menor: " + this.horaMenor);

    },

    RecargarClient() {
      if (this.audiosReq && this.audiosReq.length <= 0) {
        // Cuando carga por primera vez si los audios estan vacios los recarga para ver si existen mas
        console.log("AudiosDex: RECARGANDO --------");
        this.loadClient();
      }
    },
    async loadClient() {
      // console.log("AudiosDex: i--------------------");
      const ret = await Storage.get({ key: "clientData" });
      if (ret.value) this.clientData = JSON.parse(ret.value);
      else console.log("AudiosDex: no se pudo consultar clientData del Storage");
      const clientParameter = { id: this.clientData.link ? this.clientData.link : "" };
      axios.post("https://vybroo.net/client", clientParameter)
        .then(response => {
          // console.log(response);

          this.ddata = response.data;
          this.emitter.emit("g-data", this.ddata);

          this.audiosReq = response.data.oaudios;
          // console.log(this.audiosReq);
        })
        .catch(error => { 
          this.loadClientVBack(clientParameter, error);
        });
      // console.log("AudiosDex: f--------------------");
    },
    async loadClientVBack(clientParameter, loadClienterror) {
 
      console.log("clientParameter: ", clientParameter.id);
      axios.post("/transmito/vbk_get_client/?link="+clientParameter.id)
        .then(response => {
          this.ddata = response.data;
          this.emitter.emit("g-data", this.ddata);
          this.audiosReq = response.data.oaudios;
        })
        .catch(error => {
          console.error("AudiosDex: There was an error!",loadClienterror);
          console.error("vBACKAudiosDex: There was an error!", error);
        });
    },
    async confirmarAudioDemanda(audio) {
      try {
        const alert = await alertController
          .create({
            header: "Aviso",
            message: "¿Deseas reproducir este audio? se pausará la cancion actual.",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  console.log('Reproducir audio a demanda pulsado, audio: ' + JSON.stringify(audio));
                  // this.cambiarCategoria(idCategoria).then
                  // this.getClientData("categoriaSeleccionada");
                  this.reproducirAudio(audio);
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  console.log('Cancelar pulsado');
                }
              },
            ]
          });
        return alert.present();
      } catch (e) {
        this.presentAlertConfirm2("Ha ocurrido un error en la función confirmarCambioCategoria", e);
      }
    },
    async reproducirAudio(jAudio) {
      try {
        console.log(jAudio);
        Storage.remove({ key: 'audioDemanda' });
        Storage.set({
          key: "audioDemanda",
          value: JSON.stringify(jAudio),
        })
          .then
        console.log("Asignado a storage exitosamente - " + jAudio);
        this.getClientData("audioDemanda");
        this.$router.replace("/tabs/tabReproductor");
      } catch (e) {
        this.presentAlertConfirm2("Ha ocurrido un error en la función reproducirAudio", e);
      }
    },
    async getClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        if (ret.value) {
          this.audioDemanda = JSON.parse(ret.value);
          console.log("Recuperado de storage, key:" + keyName + " - " + ret.value);
          console.log(JSON.parse(ret.value));
        } else {
          console.log("No se pudo recuperar de storage, key:" + keyName);

        }
      } catch (e) {
        this.presentAlertConfirm2("Ha ocurrido un error en la función getClientData", e);
      }
    },

    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    getddata() {
      this.emitter.emit("g-data", this.ddata);
    },
    getddata2() {
      setTimeout(() => {
        console.log('getddata');
        this.emitter.emit("getddata", 'asd');
      }, 1000);
    },

    applyPermissions() {
      const tabCategorias = document.getElementById('l_tabCategorias');
      if (tabCategorias) {
        if (this.permissions.show_categories) {
          tabCategorias.disabled = false;
          tabCategorias.style.display = "block";
        } else {
          tabCategorias.disabled = true;
          tabCategorias.style.display = "none";
        }
      }

      const tabSucursales = document.getElementById('l_tabSucursales');
      if (tabSucursales) {
        if (this.permissions.show_branches) {
          tabSucursales.disabled = false;
          tabSucursales.style.display = "block";
        } else {
          tabSucursales.disabled = true;
          tabSucursales.style.display = "none";
        }
      }

      const tabAudios = document.getElementById('l_tabAudios');
      if (tabAudios) {
        if (this.permissions.show_ondemand) {
          tabAudios.disabled = false;
          tabAudios.style.display = "block";
        } else {
          tabAudios.disabled = true;
          tabAudios.style.display = "none";
        }
      }

      const tabVariantes = document.getElementById('l_tabVariantes');
      if (tabVariantes) {
        if (this.permissions.show_branches) {
          tabVariantes.disabled = false;
          tabVariantes.style.display = "block";
        } else {
          tabVariantes.disabled = true;
          tabVariantes.style.display = "none";
        }
      }

      const tabLinks = document.getElementById('l_tabLinks');
      if (tabLinks) {
        if (this.permissions.show_links) {
          tabLinks.disabled = false;
          tabLinks.style.display = "block";
        } else {
          tabLinks.disabled = true;
          tabLinks.style.display = "none";
        }
      }

      if (!this.permissions.show_categories && !this.permissions.show_branches && !this.permissions.show_ondemand && !this.permissions.show_links) {
        this.repSolo = true;
      }
    },
  },
  mounted() {
    // get device info
    this.device = Capacitor.getPlatform();
    console.log("Device: " + this.device);
    this.loadClient();
    this.emitter.on("getddata", getddata => {
      this.getddata(); this.loadClient();
      console.log("getddata:" + getddata);
    });
    this.emitter.on("permissions", permissions => { this.permissions = permissions; this.applyPermissions(); });

    // Obtiene la zona horaria del cliente
    // var tz = geo.timezone;
    // console.log("Zona horaria: " + tz + " | GTM: " + moment().tz(tz).format('Z'));
    // this.horaActual = moment().tz(tz).format('h:mm:ss a');
    this.load_image_client();


    this.getServerTime();
    setInterval(() => {


      //calcular la hora actual en base a la hora del servidor guardada en this.horaActual
      let horaActual = moment(this.horaActual, "h:mm:ss a");
      let horaActualMasUnSegundo = horaActual.add(1, 'seconds');
      this.horaActual = horaActualMasUnSegundo.format("h:mm:ss a");
      this.emitter.emit('hora_actual', this.horaActual);

      if (this.token_expiration_date && this.token_expiration_date < horaActualMasUnSegundo) {
        console.info("%c La sesión de administrador expiró", 'background: #000; color: yellow; font-size: 14px;');
        console.log("token_expiration_date: " + this.token_expiration_date);
        console.log("horaActual: " + horaActualMasUnSegundo);
        Storage.remove({ key: 'authToken' });
        Storage.remove({ key: 'cabina_admin_user' });
        Storage.remove({ key: 'cabina_admin_channel_list' });
        this.cabina_user = null;
        this.token_expiration_date = null;
      } else {
        if (this.token_expiration_date) {
          //calcular tiempo restante e imprimirlo en consola
          let tiempoRestanteHoras = moment(this.token_expiration_date).diff(horaActualMasUnSegundo, 'hours');
          let tiempoRestanteMinutos = moment(this.token_expiration_date).diff(horaActualMasUnSegundo, 'minutes');
          let tiempoRestanteSegundos = moment(this.token_expiration_date).diff(horaActualMasUnSegundo, 'seconds');

          //imprimir los ultimos 10 segundos de la sesión de administrador
          if (tiempoRestanteSegundos <= 10) {
            console.info("%c Tiempo restante de sesión de administrador: " + tiempoRestanteSegundos + " segundos", 'background: #000; color: yellow; font-size: 14px;');
          }
          // console.info("%c Tiempo restante de sesión de administrador: " + tiempoRestanteSegundos + " segundos" , 'background: #000; color: yellow; font-size: 14px;');
        }
      }
    }, 1000);

    this.emitter.on("set-client", () => {
      console.log("Emitido set-client");
      this.load_image_client();
    });

    this.emitter.on("cabina_admin_user", (user) => {
      console.log("Emitido cabina_admin_user: " + user);
      this.cabina_user = user;
      this.getStorageAuthToken();
    });

    this.emitter.on("sonara", (data) => {
      this.sonara = data;
      console.log("Emitido sonara: " + data);
    });

    this.emitter.on("memorySize", (data) => {
      this.memorySize = data; 
    });
  }
};
</script>

<style scoped>
body {
  margin-top: var(--ion-safe-area-top);
}

.memorySizeGrid {
  width: 100%;
  margin: auto;
  background: transparent;
    position: inherit;    
}
.storeItem {
  --padding-start: 0px;
}
 
.storeAnimation {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
    background: linear-gradient(-45deg, #e24f22, #e73c7e, #0689b9, #06ce9f);
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.log_user_on .tb_cabina_login {
  display: none;
}

.log_user_off .tb_cabina_user_logged {
  display: none;
}

.txtContactoWhats {
  color: #fff !important;
}

.hora-actual {
  text-align: center;
  font-weight: bold;
    width: 100%;
    font-size: 12px;
    color: #aeaeae;
    height: 20px;
    padding: 0px 0px 0px 0px;
    margin: 0px !important;
    line-height: 20px; 
    background: #141414;
}

.memorySizeRow {
  position: relative;
  width: 100%;
  margin: auto;
}
.memorySizeIcon { 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin: 0px;
  background: #2c2c2c;
    border-radius: 3px;
    border: solid #2c2c2c 1px;
}
.memorySizeIcon:hover {
    background: #393939;
    border: solid #666 1px;
}
.memorySizeInfo {
  text-decoration: none;
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.memorySizeIcondiv {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  position: absolute;
  right: 0;
}
.memorySizeInfodiv {
  width: calc(100% - 50px);
  max-width: calc(100% - 50px);
  min-width: calc(100% - 50px);
}

.memorySizeIcon ion-icon {
  width: 35px;
  height: 35px;
  color: #fff;
  margin: 0px !important;
}

.logoutSection {
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  padding: 10px !important;
  margin: 0 auto;
}

.tb_cerrarS {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  border-radius: 25px 5px 5px 25px;
  border: solid 1px rgb(45 45 45);
}

.tb_cerrarS img {
  object-fit: cover;
  border-radius: 50%;
  width: 50px;
  float: left;
  border: 1px solid rgb(45 45 45);
}

.tb_cerrarS .link {
  color: #fff;
  font-size: 15px !important;
  font-weight: 600;
  text-decoration: none;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  float: left;
  line-height: 50px;
  padding: 0px 4px 0px 6px;
  margin: 0px !important;
}

.tb_cerrarS .link small {
  color: #808080;
  font-weight: 300;
  font-size: 15px;
  margin-right: 3px;
}

.tb_cerrarS .link_chequeto {
  font-size: 12px !important;
}

.tb_cerrarS .icon_closee {
  width: 30px;
  display: grid;
  color: #fff;
}

.tb_cerrarS .icon_closee ion-icon {
  width: 30px !important;
  height: 50px !important;
  margin: 0 !important;
  background: #00000000;
}

.admin_area {
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, .2);
}

.admin_area {
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, .2);
  border-radius: 4px;
  border: solid 1px rgb(45 45 45);
}

.admin_area .admin_title {
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.admin_area .tb_cabina_login {
  width: 100%;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.10);
  border: 1px solid rgb(45 45 45);
  color: #fff;
}

.admin_area .tb_cabina_user_logged {
  width: 100%;
  text-align: left;
  line-height: 6px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.10);
  border: 1px solid rgb(45 45 45);
}

.admin_area .tb_cabina_user_logged .title_icon {
  width: calc(100% - 30px);
  float: left;
  display: block;
}

.admin_area .tb_cabina_user_logged .title_icon img {
  width: 30px;
  float: left;
  filter: invert(1);
  margin: 5px 0;
}

.admin_area .tb_cabina_user_logged .title_icon .title_name {
  height: 40px;
  line-height: 40px;
  width: calc(100% - 40px);
  float: left;
  padding: 0px 0px 0px 5px;
  margin: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
}


.admin_area .title_logout {
  height: 40px;
  display: flex;
  line-height: 40px;
  width: 30px;
  color: #fff;
}

.admin_area .title_logout ion-icon {
  margin: 0px;
  width: 30px;
  height: 40px;
  background: #00000000;
}

.bottom-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 25px 25px 0 0;
  border: solid 1px rgb(45 45 45);
}

.back_menu_o {
  width: 100%;
  height: calc(100% -102);
  background-color: #000;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.footer_menu {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
}

#inbox-list {
  position: relative;
  z-index: 1;
}







/* DROPDOWN -----------------------*/
.dropdown_p .audios_row {
  display: none;
}

.dropdown_pOpen .audios_row {
  display: block;
}

.dropdown_arrow {}

.dropdown_p .dropdown_arrow {
  position: absolute;
  right: 15px;
  top: 17px;
  font-size: 20px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: linear;
}

.dropdown_pOpen .dropdown_arrow {
  transform: rotate(-90deg);
}

/* DROPDOWN -----------------------*/


.l_tabAudios .link_item {
  padding: 0px 0;
  --background-color: transparent;
  margin: 0px 0px 0px 10px;
  width: 100%;
  --background: transparent;
}

.l_tabAudios .link_item:hover {
  --background: #2c2c2c;
}

.audios_row {
  padding: 0px;
}

.l_tabAudios .link_items {
  padding: 0px;
  background-color: transparent;
}

.l_tabAudios ion-grid {
  margin: 0px;
}

.l_tabAudios .headerTop {
  --background-color: #161616;
  background-color: #161616;
}

.l_tabAudios .play_button_link {
  float: left;
  font-size: 30px;
  line-height: 30px;
  margin: 0px 5px 0px 0px;
}

.l_tabAudios:hover {
  --background: #161616;
}

.l_tabAudios .tabAudios_a {
  margin: 14px 0px 12px 0px;
}

.l_tabAudios .link_items .selected {
  --background: linear-gradient(to right, #04949c, #00a8ad);
}

.l_tabAudios .link_item h2 {
  font-weight: bold;
}

.l_tabAudios .link_items .play {
  display: block;
}

.l_tabAudios .link_items .pause {
  display: none;
}

.l_tabAudios .link_items .selected .play {
  display: none;
}

.l_tabAudios .link_items .selected .pause {
  display: block;
}

.l_tabAudios .link_items .play,
.l_tabAudios .link_items .pause {
  margin: 0px auto !important;
  font-size: 25px;
  line-height: 25px;
}

.l_tabAudios .link_item .selected .text_p .text {
  color: #FFF;
}

.l_tabAudios .link_item .text {
  margin: 0px 0px;
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
}

/*
.l_tabAudios .link_item:hover .text {
  display: inline-block;
  position: relative;
  animation: 5s linear 0s infinite  move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}*/
.l_tabAudios .left_div_link {
  width: 100%;
  padding: 0px;
}

@media only screen and (min-width: 768px) {
  .vybroostudioinmenu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .hiddenMenuItem {
    display: none !important;
  }
}
</style>